// extracted by mini-css-extract-plugin
export var darkItem = "DetailTable-module--darkItem--DW9h6";
export var darkItemText = "DetailTable-module--darkItemText--rWLmU";
export var darkItemTextBig = "DetailTable-module--darkItemTextBig--w5ngN";
export var darkItemTitle = "DetailTable-module--darkItemTitle--XjFey";
export var headerTable = "DetailTable-module--headerTable--VVdY+";
export var icon = "DetailTable-module--icon--Ay3bP";
export var iconContainer = "DetailTable-module--iconContainer--Oot+d";
export var itemContainer = "DetailTable-module--itemContainer--2o5lQ";
export var itemMonth = "DetailTable-module--itemMonth--UT+rc";
export var itemPrice = "DetailTable-module--itemPrice--3UZAw";
export var itemTitle = "DetailTable-module--itemTitle--kQEFk";
export var lightItem = "DetailTable-module--lightItem--pUWxS";
export var lightItemText = "DetailTable-module--lightItemText--ZKS26";
export var lightItemTextBig = "DetailTable-module--lightItemTextBig--NAmOO";
export var lightItemTitle = "DetailTable-module--lightItemTitle--htm6W";
export var smallInfo = "DetailTable-module--smallInfo--RKJkW";
export var tableHeader = "DetailTable-module--tableHeader--2SETr";