import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './DetailTable.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import * as mainStyle from '../../index.module.scss';

import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/pro-solid-svg-icons";

import {StaticImage} from "gatsby-plugin-image";

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../../swiperStuff.scss"
import "./swiper.scss"

type DetailTableProps = {
    plans: []
}

function DetailTable(props: DetailTableProps) {
    const breakpoints = {
        991: {
            slidesPerView: 4
        },
        0: {
            slidesPerView: 1
        }
    };
    return <>
        <Row className={mainStyle.box} id={'detailTable'}>
            <Row className={style.headerTable}>
                <Col lg={4} xs={6}>
                    <Row className={style.itemTable}>
                        <Col xs={12} className={style.tableHeader}/>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Zugang zum Händler:innenportal'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Unbegrenzte Produktlistungen'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Eigener Online-Shop auf unserem Marktplatz'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Versand-Starterpaket'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Marktplatzanleitung'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Vorstellungspost auf unseren Social-Media-Kanälen'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Automatisierte Rechnungsstellung an deine Kund:innen'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Automatische Auszahlung deiner Umsätze auf dein Konto'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Abwicklung deiner Kund:innenservicefälle inkl. Paketnachforschungen'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Unbegrenzter Verkauf deiner Artikel'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Möglichkeit zum Verkauf deiner Produkte in ganz Österreich'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Abwicklung von Großaufträgen'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Persönlicher Händler:innenservice- und Beratung'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'Starter +'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                {'Statistik Berichte & Expertenberatung'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                {'SEO & Online Marketing Beratung & Unterstützung'}
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"} style={{marginTop: "25px"}}>
                            <div className={style.lightItemTitle} style={{fontWeight: "bold"}}>
                                Monatliche Service- und Marktplatzgebühr
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                Einmalige Startgebühr
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.lightItemTitle}>
                                Provision
                            </div>
                        </Col>
                        <Col xs={12} className={style.itemContainer + " headContainer"}>
                            <div className={style.darkItemTitle}>
                                Zusätzliche Bearbeitungsgebühr bei Großaufträgen
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col lg={8} xs={6}>
                    <Row>
                        <Swiper
                            navigation={true}
                            slidesPerView={3}
                            modules={[Navigation]}
                            breakpoints={breakpoints}
                            className={style.mySwiper + " detailTableSwiper"}
                        >
                            {props.plans ?
                                props.plans.map((item, key) => <SwiperSlide>
                                    <Col xs={12} className={style.tableHeader}>
                                        <div style={{backgroundColor: item.Color}}
                                             className={style.iconContainer}>
                                            <img src={item.ImageLink}

                                                 placeholder={"traceSVG"} alt={"background"}/>
                                        </div>
                                        <div className={style.itemTitle}>
                                            {item.Title}
                                        </div>
                                        <div className={style.itemPrice} style={{color: item.Color}}>
                                            {item.Price}
                                        </div>
                                        <div className={style.itemMonth}>
                                            Pro Monat
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check1 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check2 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check3 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check4 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check5 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check6 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check7 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check8 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check9 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check10 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check11 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check12 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check13 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check14 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItem}>
                                            {item.Check15 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItem}>
                                            {item.Check16 ?
                                                <FontAwesomeIcon style={{color: item.Color}} icon={faCheck}/> :
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                            }
                                        </div>
                                    </Col>

                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItemTextBig} style={{marginTop: "25px"}}>
                                            {item.Price}
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        {item.StartPrice ?
                                            <div className={style.darkItemText}>
                                                {item.StartPrice}
                                            </div> :
                                            <div className={style.darkItem}>
                                                <FontAwesomeIcon style={{color: "#FFFFFF"}} icon={faTimes}/>
                                                <span style={{color: "#FFFFFF"}}>*</span>
                                            </div>
                                        }

                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.lightItemText}>
                                            {item.Provision}
                                        </div>
                                    </Col>
                                    <Col xs={12} className={style.itemContainer}>
                                        <div className={style.darkItemText}>
                                            {item.Fees}
                                        </div>
                                    </Col>
                                </SwiperSlide>) : null}
                        </Swiper>
                    </Row>
                </Col>
            </Row>
            <small>* Alle Preise netto </small>
            <small>* Preisanpassungen und Änderungen jederzeit vorbehalten.</small>
            <small>* Für alle Kleinunternehmer:innen gelten die netto Preise als brutto Preise.</small>
            <small>* Als Kleinunternehmer:in erhältst du 2% Rabatt auf unsere Provision.</small>
            <small>* Für die ersten fünfzig Produzent:innen entfällt die Startgebühr.</small>
        </Row>
    </>;
}

export default DetailTable;
